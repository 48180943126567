<template>
  <AppPage title="Feed">
    <template v-slot:app-bar>
      <v-img
        contain
        src="@/components/svg/logo.svg"
        height="30"
        position="center"
        class="mr-auto"
      ></v-img>
    </template>

    <v-fab-transition>
      <v-btn
        v-show="!loading"
        dark
        fixed
        right
        fab
        color="primary"
        to="/create-post"
        style="bottom:100px; box-shadow: 0px 4px 27px rgba(104, 189, 232, 0.46);"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-card v-if="!loading && !feeds.length" elevation="0">
      <v-card-text class="text-center"> No Feeds :(</v-card-text>
    </v-card>

    <div class="feed-list ">
      <FeedCard
        v-for="(feed, index) in feeds"
        :key="index"
        :feed="feed"
        @open-comments="commentsHandler"
        @toggle-like="(id) => toggleLikeHandler(id, true)"
        @report-post="reportPostHandler"
      />
      <div class="" v-intersect="infiniteScrolling"></div>
    </div>
    <div class="pa-3" v-if="loading">
      <v-skeleton-loader
        v-for="n in 2"
        :key="n"
        class="mb-4"
        type="list-item-avatar,sentences, image"
      ></v-skeleton-loader>
    </div>
  </AppPage>
</template>

<script>
import FeedCard from "@/components/cards/feed-card";
import postActionMix from "@/mixin/post-action-mix";
export default {
  mixins: [postActionMix],
  name: "pet-list",
  components: { FeedCard },
  computed: {},
  methods: {
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.hasNext && !this.loading && isIntersecting) {
        this.FETCH_FEED();
      }
    },
  },
  created() {},
  beforeDestroy() {
    this.CLEAR_FEED();
  },
};
</script>

<style lang="scss" scoped>
.feed-list {
  position: relative;

  .light-bg {
    height: 3rem;
    margin-bottom: -1rem;
  }
}
</style>
